import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomepageLayout from '../components/HomepageLayout'

const IndexPage = () => (
  <Layout>
    <SEO title="Revendas" lang="pt" keywords={['software', 'tecnology', 'web', 'b2b', 'e-comerce']}  />
    <HomepageLayout showLogo={false} page='revendas'>
    </HomepageLayout>
  </Layout>
)

export default IndexPage
