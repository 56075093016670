import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
  Header,
} from 'semantic-ui-react'
import { Link } from 'gatsby'
import FluidImageLogo from './images/FluidImageLogo'
import Footer from './Footer'


/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text >
    <div style={{ maxWidth: '800px'}}>
      <FluidImageLogo maxWidth='800'/>
    </div>

    <Header
      as='h2'
      content='Tecnologia e inovação ao seu alcançe!'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />

  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children, showLogo, page } = this.props
    const { fixed } = this.state

    return (
      <>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >

          <Segment
            inverted
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical
          >
            {showLogo ? <HomepageHeading /> : null}
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={true}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                { fixed
               ? <Menu.Item >
                   <img src='logo-pequeno.png' alt='IZIONE'/>
                 </Menu.Item>
              :  null }
                <Menu.Item as={Link} active={page === 'home'} to='/'>
                   Inicio
                </Menu.Item>
                <Menu.Item as={Link} active={page === 'produtos'} to='/produtos'>
                  Produtos
                </Menu.Item>
                <Menu.Item as={Link} active={page === 'servicos'} to='/servicos'>
                    Serviços
                </Menu.Item>
                {
                // <Menu.Item as={Link} active={page === 'revendas'}  to='/revendas'>
                //     Revendas
                // </Menu.Item>
              }
                <Menu.Item position='right' as={Link} to='/contato'>
                  <Button inverted={!fixed} color='yellow' >
                    Contato  <Icon className='address card outline' style={{marginLeft:5 }}/>
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>
        <React.Fragment>
          {children}
        </React.Fragment>
      </>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, page, showLogo } = this.props
    const { sidebarOpened } = this.state

    return (
      <>
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={Link} to='/' active={page === 'home'}>
            Inicio
          </Menu.Item>
          <Menu.Item as={Link} to='/produtos' active={page === 'produtos'}>
            Produtos
          </Menu.Item>
          <Menu.Item as={Link} to='/servicos' active={page === 'servicos'}>
            Serviços
          </Menu.Item>
          {// <Menu.Item as={Link} to='/revendas' active={page === 'revendas'}>
          //   Revendas
          // </Menu.Item>
          }
          <Menu.Item as={Link} to='/contato' ctive={page === 'contato'}>
              Contato
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Link to='/contato'>
                    <Button color='yellow' >
                      Contato  <Icon className='address card outline' style={{marginLeft:5 }}/>
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            </Container>
            {showLogo ? <HomepageHeading mobile /> : null}
          </Segment>
          <React.Fragment>
            {children}
          </React.Fragment>
        </Sidebar.Pusher>
    </>)
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children, showLogo, page }) => (
  <>
    <div id='menuDesk'>
      <DesktopContainer showLogo={showLogo} page={page}><React.Fragment>{children}</React.Fragment></DesktopContainer>
    </div>
    <div id='menuMobile'>
      <MobileContainer showLogo={showLogo}><React.Fragment>{children}</React.Fragment></MobileContainer>
    </div>
  </>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}


const HomepageLayout = ({ children, showLogo=true, page }) => (
  <ResponsiveContainer showLogo={showLogo} page={page} >
    <React.Fragment>
      {children}
    </React.Fragment>
    <Footer />
  </ResponsiveContainer>
)

export default HomepageLayout
