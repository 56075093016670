import React  from 'react'
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
  Icon
} from 'semantic-ui-react'
import { Link } from 'gatsby'

const Footer = () =>(
  <Segment inverted vertical style={{ padding: '5em 0em' }}>
    <Container>
      <Grid divided inverted stackable>
        <Grid.Row>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Produtos' />
            <List link inverted>
              <List.Item as={Link} to='/produtos#apps'> Apps </List.Item>
              <List.Item as={Link} to='/produtos#bots'> Bots </List.Item>
              <List.Item as={Link} to='/produtos#ia'> Inteligência Artificial </List.Item>
              <List.Item as={Link} to='/produtos#ecomerce'> E-Comerce </List.Item>
              <List.Item as={Link} to='/produtos#integradores'> Integrações </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Serviços' />
            <List link inverted>
              <List.Item as={Link} to='/servicos#consultoria'>Consultoria</List.Item>
              <List.Item as={Link} to='/servicos#terceirize'>Terceirização</List.Item>
              <List.Item as={Link} to='/servicos#arquitetura'>Arquitetura</List.Item>
              <List.Item as={Link} to='/servicos#poc'>Provas de conceito</List.Item>
            </List>
          </Grid.Column>
        {  // <Grid.Column width={3}>
          //   <Header inverted as='h4' content='Revendas' />
          //   <List link inverted>
          //     <List.Item as={Link} to='/revendas#erp'>ERP em nuvem</List.Item>
          //     <List.Item as={Link} to='/revendas#pet'>Pet Shop</List.Item>
          //     <List.Item as={Link} to='/revendas#cte'>Transportadoras</List.Item>
          //     <List.Item as={Link} to='/revendas#crm'>CRM Web/Mobile</List.Item>
          //   </List>
          // </Grid.Column>
        }
          <Grid.Column width={7}>
            <Header as='h4' inverted>
              Izione Sistemas
            </Header>
            <p>
              Entre em contato pelo E-Mail:
              <a href='mailto:contato@izione.com.br' style={{color:'yellow'}}>contato@izione.com.br</a>
              <br />
              ou mande uma mensagem agora mesmo:
              <Link to='/contato'>
                <Icon className='address card outline' style={{marginLeft:5 }} color='yellow'/>
              </Link>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
)

export default Footer
